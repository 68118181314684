/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import r from "../core/Collection.js";
import { referenceSetter as t } from "../core/collectionUtils.js";
import s from "../core/Logger.js";
import { isPromiseLike as o } from "../core/promiseUtils.js";
import { property as a } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import n from "../layers/Layer.js";
function d(e, r, t) {
  let s, o;
  if (e) for (let a = 0, i = e.length; a < i; a++) {
    if (s = e.at(a), s?.[r] === t) return s;
    if ("group" === s?.type && (o = d(s.layers, r, t), o)) return o;
  }
}
const l = l => {
  let y = class extends l {
    constructor(...e) {
      super(...e), this.layers = new r();
      const t = e => {
          e.parent && "remove" in e.parent && e.parent.remove(e);
        },
        o = e => {
          e.parent = this, this.layerAdded(e), "elevation" !== e.type && "base-elevation" !== e.type || s.getLogger(this).error(`Layer 'title:${e.title}, id:${e.id}' of type '${e.type}' is not supported as an operational layer and will therefore be ignored.`);
        },
        a = e => {
          e.parent = null, this.layerRemoved(e);
        };
      this.addHandles([this.layers.on("before-add", e => {
        if (e.item === this) return e.preventDefault(), void s.getLogger(this).error("#add()", "Cannot add layer to itself.");
        t(e.item);
      }), this.layers.on("after-add", e => o(e.item)), this.layers.on("after-remove", e => a(e.item))]);
    }
    destroy() {
      const e = this.layers.toArray();
      for (const r of e) r.destroy();
      this.layers.destroy();
    }
    set layers(e) {
      this._set("layers", t(e, this._get("layers")));
    }
    add(e, r) {
      const t = this.layers;
      if (r = t.getNextIndex(r), e instanceof n) {
        const s = e;
        s.parent === this ? this.reorder(s, r) : t.add(s, r);
      } else o(e) ? e.then(e => {
        this.destroyed || this.add(e, r);
      }) : s.getLogger(this).error("#add()", "The item being added is not a Layer or a Promise that resolves to a Layer.");
    }
    addMany(e, r) {
      const t = this.layers;
      let s = t.getNextIndex(r);
      e.slice().forEach(e => {
        e.parent !== this ? (t.add(e, s), s += 1) : this.reorder(e, s);
      });
    }
    findLayerById(e) {
      return d(this.layers, "id", e);
    }
    findLayerByUid(e) {
      return d(this.layers, "uid", e);
    }
    remove(e) {
      return this.layers.remove(e);
    }
    removeMany(e) {
      return this.layers.removeMany(e);
    }
    removeAll() {
      return this.layers.removeAll();
    }
    reorder(e, r) {
      return this.layers.reorder(e, r);
    }
    layerAdded(e) {}
    layerRemoved(e) {}
  };
  return e([a()], y.prototype, "layers", null), y = e([i("esri.support.LayersMixin")], y), y;
};
export { l as LayersMixin };